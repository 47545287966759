<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew"/>
            <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                    :disabled="!selectedUsers || !selectedUsers.length"/>
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="users" v-model:selection="selectedUsers" dataKey="id" :filters="filters" editMode="cell">
          <template #header>
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <h5 class="p-m-0">Gebruikers beheren</h5>
              <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Zoeken..."/>
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="logo" header="Logo">
            <template #body="{data}">
              <span style="cursor:pointer">
                <template v-if="data.logo">
                  <img :src="getLogoUrl(data.logo)" width="50" style="border-radius:50%" />
                </template>
                <i class="p-ml-2 pi pi-pencil"></i>
              </span>
            </template>
            <template #editor="slotProps">
              <FileUpload
                  id="logo"
                  name="logo"
                  mode="basic"
                  accept="image/*"
                  :maxFileSize="500000"
                  invalidFileSizeMessage="{0}: Ongeldige bestandsgrootte, bestandsgrootte moet kleiner zijn dan {1}."
                  :url="getUploadUrl(slotProps.data.id)"
                  @upload="onUpload(slotProps.data.id)"
                  @before-send="beforeUpload"
              />
            </template>
          </Column>
          <Column field="username" header="Gebruikersnaam" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.username }}
            </template>
          </Column>
          <Column field="roles" header="Rechten" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.roles.join(' - ') }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-eject" class="p-button-rounded p-mr-2"
                      :disabled="slotProps.data.username === $root.userService.getUser().username || $root.userService.hasAdmin(slotProps.data)"
                      @click="impersonateUser(slotProps.data)"/>
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                      @click="editUser(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                      @click="confirmDeleteUser(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="userDialog" :style="{width: '450px'}" header="Gebruiker" :modal="true" class="p-fluid">
          <div class="p-field">
            <label for="username">Gebruikersnaam</label>
            <InputText id="username" v-model.trim="user.username" required="true" autofocus
                       :class="{'p-invalid': submitted && !user.username}"/>
            <small class="p-invalid" v-if="submitted && !user.username">Gebruikersnaam is verplicht.</small>
          </div>
          <div class="p-field">
            <label for="password">Wachtwoord</label>
            <Password id="password" v-model="user.password" :required="user.id" toggleMask
                      :class="{'p-invalid': submitted && !user.id && !user.password}"/>
            <small v-if="user.id">Alleen invullen als het wachtwoord aangepast moet worden</small>
            <small class="p-invalid" v-if="submitted && !user.id && !user.password">Wachtwoord is verplicht.</small>
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveUser"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span v-if="user">Weet je zeker dat je <b>{{ user.username }}</b> wilt verwijderen?</span>
          </div>
          <template #footer>
            <Button label="Nee" icon="pi pi-times" class="p-button-text" @click="deleteUserDialog = false"/>
            <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteUser"/>
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span v-if="user">Weet je zeker dat je de geselecteerde gebruikers wilt verwijderen?</span>
          </div>
          <template #footer>
            <Button label="Nee" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
            <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUsers"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api'

export default {
  data() {
    return {
      users: null,
      userDialog: false,
      deleteUserDialog: false,
      deleteUsersDialog: false,
      user: {},
      selectedUsers: null,
      filters: {},
      submitted: false,
      random: 1
    }
  },
  created() {
    this.initFilters()
  },
  mounted() {
    this.$root.userService.getUsers().then(data => this.users = data)
  },
  methods: {
    getUploadUrl(userId) {
      return `${process.env.VUE_APP_BASE_URL}/admin/user/upload-logo?id=${userId}`
    },
    onUpload() {
      this.$root.userService.getUsers().then(data => {
        this.random++
        this.users = data
        this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Logo aangepast', life: 3000});
      })
    },
    beforeUpload(event) {
      event.xhr.setRequestHeader('X-AUTH-TOKEN', sessionStorage.getItem('apiToken'))
    },
    getLogoUrl(logo) {
      return `${process.env.VUE_APP_BASE_URL}/upload${logo}?random=${this.random}`
    },
    openNew() {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
    async saveUser() {
      this.submitted = true;
      if (this.user.username.trim() && (this.user.id || this.user.password)) {
        if (this.user.id) {
          try {
            await this.$root.userService.editUser(this.user)
            this.users[this.findIndexById(this.user.id)] = this.user;
            this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Gebruiker aangepast', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data.msg ? e.response.data.msg : 'Room NOT Updated'})
          }
        } else {
          try {
            const result = await this.$root.userService.newUser(this.user)
            this.user = result.data
            this.users.push(this.user);
            this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Gebruiker toegevoegd', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data.msg ? e.response.data.msg : 'Gebruiker niet toegevoegd'})
          }
        }
        this.userDialog = false;
        this.user = {};
      }
    },
    impersonateUser(user) {
      this.$root.userService.impersonateUser(user)
    },
    editUser(user) {
      this.user = {...user};
      this.userDialog = true;
    },
    confirmDeleteUser(user) {
      this.user = user;
      this.deleteUserDialog = true;
    },
    async deleteUser() {
      try {
        await this.$root.userService.deleteUser(this.user)
        this.users = this.users.filter(val => val.id !== this.user.id);
        this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Gebruiker verwijderd', life: 3000})
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data.msg})
      }

      this.deleteUserDialog = false;
      this.user = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    confirmDeleteSelected() {
      this.deleteUsersDialog = true;
    },
    async deleteSelectedUsers() {
      try {
        await this.$root.userService.deleteUsers(this.selectedUsers)
        this.users = this.users.filter(val => !this.selectedUsers.includes(val));
        this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Gebruikers verwijderd', life: 3000});
        this.selectedUsers = null;
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data.msg})
      }

      this.deleteUsersDialog = false;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>
